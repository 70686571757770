import { constructFrom } from "../constructFrom.js";

export function normalizeDates(context, ...dates) {
  const normalize =
    context ||
    constructFrom.bind(
      null,
      dates.find((date) => typeof date === "object"),
    );
  return dates.map(normalize);
}
